import myRequest from '@/services/myRequest';

// 获取已发表 / 已撤回 / 草稿箱文章
export async function getArticleLists(params) {
  return myRequest('get', '/selfContent/queryContent', params);
}

// 文章管理删除文章
export async function deleteArticle(params) {
  return myRequest('post', '/selfContent/updateStateByInfoId', params);
}

export async function uploadHorizontalCover(params) {
  return myRequest('post', '/selfContent/uploadHorizontalCover', params);
}

// 文章管理修改状态
export async function updateStateByInfoId(params) {
  return myRequest('post', '/selfContent/updateStateByInfoId', params);
}
